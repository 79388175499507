import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Publishers from "./Publishers";
import Publisher from "./Publisher";
import PublisherConfigForm from "./PublisherConfigForm";
import { createHashRouter, RouterProvider } from "react-router-dom";

const router = createHashRouter([
  {
    path: "/publishers/:publisherId",
    element: <Publisher />,
  },
  {
    path: "/publisherForm/:publisherId/:newsletterId",
    element: <PublisherConfigForm />,
  },
  {
    path: "/",
    element: <Publishers />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="App">
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);
