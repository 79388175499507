import { useEffect, useState, useRef } from "react";
import PublisherCard from "./PublisherCard";
import Breadcrumb from "./Breadcrumb";
import TristateCheckbox from "react-three-state-checkbox";

function Publishers() {
  const [publishers, setPublishers] = useState([]);
  const [filteredPublishers, setFilteredPublishers] = useState([]);
  const [withNotesFilter, setWithNotesFilter] = useState(null);
  const [publishedFilter, setPublishedFilter] = useState(null);

  useEffect(() => {
    fetch("/pubindex/", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then(setPublishers);
  }, []);

  useEffect(() => {
    let filteredPublishers = publishers;
    if (withNotesFilter !== null) {
      filteredPublishers = filteredPublishers.filter(
        (publisher) => !!publisher.notes === withNotesFilter
      );
    }
    if (publishedFilter !== null) {
      filteredPublishers = filteredPublishers.filter(
        (publisher) => !!publisher.published === publishedFilter
      );
    }
    setFilteredPublishers(filteredPublishers);
  }, [publishers, withNotesFilter, publishedFilter]);

  const handleNotesFilterChange = (e) => {
    switch (withNotesFilter) {
      case null:
        setWithNotesFilter(true);
        break;
      case true:
        setWithNotesFilter(false);
        break;
      default:
        setWithNotesFilter(null);
    }
  };
  const handlePublishedFilterChange = (e) => {
    switch (publishedFilter) {
      case null:
        setPublishedFilter(true);
        break;
      case true:
        setPublishedFilter(false);
        break;
      default:
        setPublishedFilter(null);
    }
  };

  return (
    <>
      <div className="navbar p-2 mb-2 shadow-lg bg-neutral text-neutral-content">
        <div className="flex-none text-lg mx-6">Blueprint QA Review Tool</div>
        <Breadcrumb />
      </div>
      <div>
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => {
            const query = e.target.value.toLowerCase();
            setFilteredPublishers(
              publishers.filter((publisher) =>
                publisher.publisherId.toLowerCase().includes(query)
              )
            );
          }}
        />
        <label className="ml-4">
          With Notes
          <TristateCheckbox
            className="toggle"
            checked={withNotesFilter === true}
            indeterminate={withNotesFilter === null}
            onChange={handleNotesFilterChange}
          />
        </label>
        <label className="ml-4">
          Published
          <TristateCheckbox
            className="toggle"
            checked={publishedFilter === true}
            indeterminate={publishedFilter === null}
            onChange={handlePublishedFilterChange}
          />
        </label>
      </div>
      <div class="flex flex-wrap justify-center gap-6">
        {filteredPublishers.map((publisher) => (
          <PublisherCard key={publisher.publisherId} publisher={publisher} />
        ))}
      </div>
    </>
  );
}

export default Publishers;
