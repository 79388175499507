import { Link } from "react-router-dom";

function Breadcrumb({ publisherId, newsletterId }) {
  return (
    <div className="text-sm breadcrumbs">
      <ul>
        <li>
          <Link to="/">Publishers</Link>
        </li>
        {publisherId && (
          <li>
            Publisher:{" "}
            <Link to={`/publishers/${publisherId}/`}>{publisherId}</Link>
          </li>
        )}
        {newsletterId && (
          <Link to={`/publishers/${publisherId}/${newsletterId}/`}>
            {newsletterId}
          </Link>
        )}
      </ul>
    </div>
  );
}

export default Breadcrumb;
