import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import {
  Layout,
  ArticleMedium,
  Image,
  MathOperations,
  Moped,
} from "@phosphor-icons/react";

function Publishers() {
  const { publisherId } = useParams();
  const [newsletters, setNewsletters] = useState([]);
  const [status, setStatus] = useState("");
  useEffect(() => {
    fetch("/pubindex/" + publisherId, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((newsletters) =>
        newsletters.map((newsletter) =>
          newsletter.substr(0, newsletter.length - 1)
        )
      )
      .then(setNewsletters);
  }, [publisherId, setNewsletters]);

  useEffect(() => {
    if (status !== "") {
      setTimeout(() => setStatus(""), 3000);
    }
  }, [status]);
  return (
    <>
      <div className="navbar p-2 mb-2 shadow-lg bg-neutral text-neutral-content">
        <div className="flex-none text-lg mx-6">Blueprint QA Review Tool</div>
        <Breadcrumb publisherId={publisherId} />
      </div>
      <div className="text-lg">Newsletters</div>
      <div className="p-4">
        {newsletters.map((newsletter) => (
          <div
            className="card card-bordered w-full bg-base-300 border-gray-800 dark:border-gray-300 p-6 text-gray-800 dark:text-gray-300"
            key={newsletter}
          >
            <div className="block py-2">{newsletter}</div>
            <div className="flex flex-wrap flex-cols justify-evenly gap-2">
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/desktop.html`}
              >
                <button className="btn btn-accent">
                  <ArticleMedium weight="regular" className="h-6 w-6" /> Desktop
                  preview
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/desktop.jpeg`}
              >
                <button className="btn btn-accent">
                  <Image weight="duotone" className="h-6 w-6" /> Desktop ad
                  image
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/desktop.blueprint.html`}
              >
                <button className="btn btn-accent">
                  {" "}
                  <Layout weight="duotone" className="h-6 w-6" /> Desktop
                  blueprint
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/mobile.html`}
              >
                <button className="btn btn-primary">
                  <ArticleMedium weight="regular" className="h-6 w-6" /> Mobile
                  preview
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/mobile.jpeg`}
              >
                <button className="btn btn-primary">
                  <Image weight="duotone" className="h-6 w-6" /> Mobile ad image
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/mobile.blueprint.html`}
              >
                <button className="btn btn-primary">
                  {" "}
                  <Layout weight="duotone" className="h-6 w-6" /> Mobile
                  blueprint
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/publishers/${publisherId}/output/${newsletter}/variables.json`}
              >
                <button className="btn btn-neutral">
                  <MathOperations weight="bold" className="h-6 w-6" /> Analysis
                  Variables
                </button>
              </a>
              <button
                className={
                  "btn " +
                  (status === ""
                    ? "btn-secondary"
                    : status === "Saved"
                    ? "bg-green-800"
                    : "bg-red-800")
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (status === "") {
                    fetch(
                      `/pubindex/${publisherId}/output/${newsletter}/publish`,
                      {
                        credentials: "include",
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    ).then((res) => {
                      if (res.status === 200) {
                        setStatus("Saved");
                      } else {
                        setStatus("Error");
                      }
                    });
                  }
                }}
              >
                <Moped weight="duotone" className="h-6 w-6" />
                {status === "" ? "Publish" : status}
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Publishers;
