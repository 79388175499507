import { Link } from "react-router-dom";

function PublisherCard({ publisher }) {
  return (
    <Link
      to={`/publishers/${publisher.publisherId}`}
      target="_blank"
      rel="noreferer"
    >
      <div className="card card-bordered ring-2 ring-neutral-400 p-2 w-80 h-60 bg-base-300 shadow-xl justify-between">
        <div>{publisher.publisherId}</div>
        {publisher.published && (
          <div>✅ Published {publisher.published.updated}</div>
        )}
        {publisher.notes && (
          <div
            className="overflow-clip whitespace-nowrap text-ellipsis h-10"
            title={publisher.notes}
          >
            📝 {publisher.notes}
          </div>
        )}
        <figure>
          <img
            src={`publishers/${publisher.publisherId}/output/desktop.jpeg`}
            alt="desktop blueprint preview"
          />
        </figure>
      </div>
    </Link>
  );
}

export default PublisherCard;
