import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

function PubliserConfigForm() {
  const { publisherId, newsletterId } = useParams();

  const [configJson, setConfigJson] = useState({});
  const [publisherVariables, setPublisherVariables] = useState({});
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");

  const AutoProperty = (property) => (
    <div>
      {property.textArea ? (
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">{property.name}</span>
            {property.hint && (
              <span className="label-text-alt" title={property.hint}>
                ℹ️
              </span>
            )}
          </div>
          <textarea
            name={property.name}
            defaultValue={_.get(configJson, property.name)}
            className="resize textarea textarea-bordered textarea-lg"
          />
        </label>
      ) : property.styleBlock ? (
        <div>
          <ul>
            {Object.entries(publisherVariables?.[property.name] ?? {}).map(
              ([key, value]) => (
                <label
                  key={key}
                  className={
                    "form-control w-full max-w-xs" +
                    (new RegExp(filter).test(key) ? "" : " hidden")
                  }
                >
                  <div className="label">
                    <span className="label-text">{key}</span>
                  </div>
                  <input
                    type="text"
                    className="input input-sm input-bordered w-full max-w-xs placeholder-gray-500 text-gray-900 dark:text-gray-200 "
                    name={`${property.name}.${key}`}
                    placeholder={value}
                    defaultValue={configJson?.[property.name]?.[key]}
                  />
                </label>
              )
            )}
          </ul>
        </div>
      ) : (
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">{property.name}</span>
            {property.hint && (
              <span className="label-text-alt" title={property.hint}>
                ℹ️
              </span>
            )}
          </div>
          <input
            type="text"
            name={property.name}
            className="input input-sm input-bordered w-full max-w-xs placeholder-gray-500 text-gray-900 dark:text-gray-200 "
            placeholder={_.get(publisherVariables, property.name)}
            defaultValue={_.get(configJson, property.name)}
          />
        </label>
      )}
    </div>
  );

  const sponsoredByProperties = [
    {
      name: "sponsoredBySelector",
      hint: "JQuery selector for SponsoredBy example element",
    },
    {
      name: "sponsoredByStyle",
      hint: "CSS Style for SponsoredBy element",
      styleBlock: true,
    },
  ];
  const titleProperties = [
    {
      name: "titleSelector",
      hint: "JQuery selector for Title example element",
    },
    {
      name: "titleStyle",
      hint: "CSS Style for Title element",
      styleBlock: true,
    },
  ];
  const ctaProperties = [
    {
      name: "ctaSelector",
      hint: "JQuery selector for CTA example element",
    },
    { name: "ctaStyle", hint: "CSS Style for CTA element", styleBlock: true },
  ];
  const desProperties = [
    {
      name: "desSelector",
      hint: "JQuery selector for Description example element",
    },
    {
      name: "desStyle",
      hint: "CSS Style for description element",
      styleBlock: true,
    },
  ];

  const mainProperties = [
    {
      name: "previewSelector",
      hint: "JQuery selector for the location in the newsletter to insert the ad preview (Does not affect blueprint output)",
    },
    {
      name: "rootElement",
      hint: "JQuery selector for the outermost element of the newsletter content area (helps avoid invalid detection due to header/footer elements",
    },
    {
      name: "notes",
      textArea: true,
      hint: "Used for internal tracking and communications only. (Does not affect blueprint output)",
    },
    {
      name: "publisherName",
      hint: "Must match the LiveIntent publisher name exactly",
    },
    {
      name: "wrapperStyle.width",
      hint: "Width of the ad (double the value and include 'px' as units)",
    },
    {
      name: "wrapperStyle.background-color",
      hint: "Overall background color of the ad (format as #HEX, rgb(), or named color)",
    },
  ];

  useEffect(() => {
    if (status !== "") {
      setTimeout(() => setStatus(""), 3000);
    }
  }, [status]);
  useEffect(() => {
    fetch("/pubindex/" + publisherId + "/config/config.json", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(setConfigJson);
  }, [publisherId]);
  useEffect(() => {
    fetch(`/publishers/${publisherId}/output/${newsletterId}/variables.json`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(setPublisherVariables);
  }, [publisherId]);

  return (
    <>
      <input
        type="text"
        defaultValue={filter}
        placeholder="Filter"
        className="input input-sm input-bordered"
        onChange={(e) => setFilter(e.target.value)}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const submitData = {};
          for (const [key, value] of new FormData(e.target).entries()) {
            if (value !== "" && key !== "formTabs") {
              const path = key.split(".");
              let container = submitData;
              for (let i = 0; i < path.length - 1; i++) {
                if (container[path[i]] === undefined) {
                  container[path[i]] = {};
                }
                container = container[path[i]];
              }
              container[path[path.length - 1]] = value;
            }
          }
          console.log(submitData);
          fetch("/pubindex/" + publisherId + "/config/config.json", {
            credentials: "include",
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(submitData),
          }).then(
            (res) => setStatus(["Saved"]),
            (err) => setStatus(["Error"])
          );
        }}
      >
        <div className="block">
          <input
            className="btn btn-sm btn-primary fixed right-4 top-20"
            type="submit"
            value="Save"
          />
          <div className="text-green-600 font-bold fixed right-4 top-28">
            {status}
          </div>
        </div>
        <div className="max-w-screen-md">
          <div role="tablist" className="tabs tabs-lifted bg-base-300">
            <input
              type="radio"
              name="formTabs"
              role="tab"
              className="tab bg-blue-300 text-gray-600"
              aria-label="Main"
              defaultChecked
            />
            <div
              role="tabpanel"
              className="tab-content bg-base-100 border-blue-300 rounded-box p-6"
            >
              {mainProperties.map(AutoProperty)}
            </div>

            <input
              type="radio"
              name="formTabs"
              role="tab"
              className="tab bg-green-300 text-gray-600"
              aria-label="Description"
            />
            <div
              role="tabpanel"
              className="tab-content bg-base-100 border-green-300 rounded-box p-6"
            >
              {desProperties.map(AutoProperty)}
            </div>

            <input
              type="radio"
              name="formTabs"
              role="tab"
              className="tab bg-yellow-300 text-gray-600"
              aria-label="Title"
            />
            <div
              role="tabpanel"
              className="tab-content bg-base-100 border-yellow-300 rounded-box p-6"
            >
              {titleProperties.map(AutoProperty)}
            </div>

            <input
              type="radio"
              name="formTabs"
              role="tab"
              className="tab bg-orange-300 text-gray-600"
              aria-label="CTA"
            />
            <div
              role="tabpanel"
              className="tab-content bg-base-100 border-orange-300 rounded-box p-6"
            >
              {ctaProperties.map(AutoProperty)}
            </div>

            <input
              type="radio"
              name="formTabs"
              role="tab"
              className="tab active:bg-red-300 bg-red-300 text-gray-600"
              aria-label="Sponsored By"
            />
            <div
              role="tabpanel"
              className="tab-content  bg-base-100 border-red-300 rounded-box p-6"
            >
              {sponsoredByProperties.map(AutoProperty)}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default PubliserConfigForm;
